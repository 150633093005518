import React from 'react';
import PropTypes from 'prop-types';

const Selector = ({
  isMultiSelect, selected, onSelect, children,
}) => {
  const options = [];

  let tabIndex = 0;
  React.Children.forEach(children, (option) => {
    const { value } = option.props;

    const handleSelect = () => {
      if (isMultiSelect) {
        // If it is a multi-select, then we toggle the value that was clicked.
        const newSelected = { ...selected };
        newSelected[value] = !selected[value];
        onSelect(newSelected);
      } else {
        // Otherwise, we just set the clicked value to be selected
        onSelect(value);
      }
    };

    const isSelected = isMultiSelect ? selected[value] : selected === value;

    options.push(
      <div
        role="menuitem"
        tabIndex={tabIndex}
        key={`option-${value}`}
        onClick={handleSelect}
        onKeyPress={handleSelect}
      >
        {option}
        {isSelected ? 'selected' : ''}
      </div>,
    );

    tabIndex += 1;
  });

  return (
    <>
      {options}
    </>
  );
};

Selector.propTypes = {
  isMultiSelect: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.bool),
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Selector.defaultProps = {
  isMultiSelect: false,
};

const Option = ({
  caption, value,
}) => (
  <p>
    {caption}
    {' '}
    (
    {value}
    )
  </p>
);

Option.propTypes = {
  caption: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

Selector.Option = Option;

export default Selector;
