import { COVERS, PROFILES, SETTINGS } from './constants';

const to2dp = (num) => (
  Math.round((num + Number.EPSILON) * 100) / 100
);

const calculateProfilePages = (people, profileType) => {
  if (people < 0) {
    throw Error('Invalid number of people');
  }

  switch (profileType) {
    case PROFILES.HALF:
      return Math.ceil(people / 2);
    case PROFILES.ONE:
      return people;
    case PROFILES.TWO:
      return people * 2;
    default:
      throw Error('Invalid profile type');
  }
};

const calculateExtrasPrice = (extras) => {
  const price = Object.keys(extras).reduce((total, extra) => {
    // If this extra is selected, add its price to the total
    if (extras[extra]) {
      return total + SETTINGS.EXTRAS_PRICE[extra];
    }
    return total;
  }, 0);

  return to2dp(price);
};

const calculateAdditionalPagesPrice = (additionalPages) => {
  let additionalPagesPrice = 0;

  // Add a price for each additional page. First 25 are 0.10, next 25 are 0.09, rest are 0.08
  for (let i = 0; i < additionalPages; i += 1) {
    if (i < 25) {
      additionalPagesPrice += 0.1;
    } else if (i < 50) {
      additionalPagesPrice += 0.09;
    } else {
      additionalPagesPrice += 0.08;
    }
  }
  return to2dp(additionalPagesPrice);
};

const calculateCoverPrice = (coverType, copies) => {
  let coverPrice = SETTINGS.COVER_PRICE[coverType];
  if (coverType === COVERS.LEATHERETTE) {
    coverPrice += SETTINGS.LEATHERETTE_BASE_PRICE * (1 / copies);
  }
  return to2dp(coverPrice);
};

const calculateBasePrice = (people, copies, profileType) => {
  let basePrice = SETTINGS.BASE_PRICE
                      + SETTINGS.USERS_PER_COPY_GRADIENT
                      * (1.0 * (people / copies)) - 1.0;

  basePrice = Math.max(basePrice, SETTINGS.MINIMUM_PRICE);

  // Add in an additional fee based on type of profiles selected
  basePrice += SETTINGS.PROFILE_PAGES_PRICE[profileType];

  // Add in a "printing surcharge" if minimum copies isn't reached
  if (copies < SETTINGS.MIN_COPIES_WITHOUT_SURCHARGE) {
    // Divide the surcharge across each book, as the surcharge is for the entire print run.
    basePrice += 1 * (SETTINGS.PRINTING_SURCHARGE / copies);
  }
  return to2dp(basePrice);
};

const calculatePrice = (copies, people, additionalPages, profileType, coverType, extras) => {
  const standardPages = SETTINGS.STANDARD_PAGES;
  const profilePages = calculateProfilePages(people, profileType);

  const basePrice = calculateBasePrice(people, copies, profileType);
  const additionalPagesPrice = calculateAdditionalPagesPrice(additionalPages);
  const extrasPrice = calculateExtrasPrice(extras);
  const coverPrice = calculateCoverPrice(coverType, copies);

  return {
    basePrice,
    additionalPagesPrice,
    coverPrice,
    extrasPrice,
    profilePages,
    standardPages,
  };
};

const calculateMinimumAdditionalPages = (people, profileType) => {
  const profilePages = calculateProfilePages(people, profileType);

  // If there are currently less than MINIMUM_PAGES in the book (from profiles
  // and standard pages) then the additional pages must make up the value.
  let additionalPages = SETTINGS.MINIMUM_PAGES - SETTINGS.STANDARD_PAGES - profilePages;
  additionalPages = Math.max(additionalPages, 0);

  return additionalPages;
};

export { calculatePrice, calculateMinimumAdditionalPages };
