import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ title, subtitle = null, children }) => (
  <div>
    <h1>{title}</h1>
    <p>{subtitle}</p>
    {children}
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  subtitle: null,
};

export default Section;
