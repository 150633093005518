export const PROFILES = {
  HALF: 0,
  ONE: 1,
  TWO: 2,
};

export const COVERS = {
  SOFT: 0,
  HARD: 1,
  LEATHERETTE: 2,
};

export const EXTRAS = {
  FRONT_ENDPAPERS: 0,
  REAR_ENDPAPERS: 1,
  RIBBON_MARKER: 2,
  FOIL_STAMPING: 3, // TODO: Only allow this to be selected when leatherette is chosen
};

export const SETTINGS = {
  BASE_PRICE: 10,
  MINIMUM_PRICE: 4,
  USERS_PER_COPY_GRADIENT: 1,
  COVER_PRICE: {
    [COVERS.SOFT]: 0,
    [COVERS.HARD]: 4,
    [COVERS.LEATHERETTE]: 6,
  },
  PROFILE_PAGES_PRICE: {
    [PROFILES.HALF]: 0,
    [PROFILES.ONE]: 5,
    [PROFILES.TWO]: 10,
  },
  EXTRAS_PRICE: {
    [EXTRAS.FRONT_ENDPAPERS]: 2,
    [EXTRAS.REAR_ENDPAPERS]: 2,
    [EXTRAS.RIBBON_MARKER]: 0.5,
    [EXTRAS.FOIL_STAMPING]: 2,
  },
  LEATHERETTE_BASE_PRICE: 215,
  PRINTING_SURCHARGE: 70,
  MIN_COPIES_WITHOUT_SURCHARGE: 100,
  STANDARD_PAGES: 10,
  MINIMUM_PAGES: 50,
};
