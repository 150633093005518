import React from 'react';
import Layout from '../components/Layout';
import PricingCalculator from '../components/PricingCalculator';

const Pricing = () => (
  <Layout transparentHeader>
    <section className="my-12 xl:my-16">
      <div className="px-6 md:px-[30px] xl:px-[50px] 2xl:px-[110px] 2xl:max-w-screen-2xl mx-auto">
        <PricingCalculator />
      </div>
    </section>
  </Layout>
);

export default Pricing;
