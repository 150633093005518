import React, { useEffect, useState } from 'react';
import Section from './Section';
import Selector from './Selector';
import Slider from './Slider';
import Price from './Price';

import { PROFILES, COVERS, EXTRAS } from './constants';
import { calculateMinimumAdditionalPages } from './calculator';

const PricingCalculator = () => {
  const [copies, setCopies] = useState(100);
  const [people, setPeople] = useState(100);
  const [profileType, setProfileType] = useState(PROFILES.HALF);
  const [
    additionalPages,
    setAdditionalPages,
  ] = useState(calculateMinimumAdditionalPages(people, profileType));

  // TODO: Store the 'desired additional pages' and feed that into minimum pages calculation
  const [coverType, setCoverType] = useState(COVERS.SOFT);
  const [extras, setExtras] = useState({
    [EXTRAS.FRONT_ENDPAPERS]: false,
    [EXTRAS.REAR_ENDPAPERS]: false,
    [EXTRAS.RIBBON_MARKER]: false,
    [EXTRAS.FOIL_STAMPING]: false,
  });

  useEffect(() => {
    setAdditionalPages(
      Math.max(
        additionalPages,
        calculateMinimumAdditionalPages(people, profileType),
      ),
    );
  }, [people]);

  return (
    <>
      <Section title="Copies and People" subtitle="How many copies of your book will you order, and how many people need profiles?">
        <Slider
          title="Copies"
          subtitle="Minimum 10 copies. Price per book decreases as number of copies increases."
          value={copies}
          minimum={10}
          maximum={300}
          onChange={setCopies}
        />
        <Slider
          title="People"
          subtitle="Determines number of pages of profiles."
          value={people}
          minimum={0}
          maximum={300}
          onChange={setPeople}
        />
      </Section>

      <Section title="Profiles per Page">
        <Selector selected={profileType} onSelect={setProfileType}>
          <Selector.Option caption="Half page per person" value={PROFILES.HALF} />
          <Selector.Option caption="One page per person" value={PROFILES.ONE} />
          <Selector.Option caption="Two page per person" value={PROFILES.TWO} />
        </Selector>
      </Section>

      <Section title="Additional Pages" subtitle="Articles, montages, polls, index, notes and self-designed pages.">
        <Slider
          title="Pages"
          subtitle="Your book must have at least 50 pages in total. Price per page decreases as number of pages increases."
          value={additionalPages}
          minimum={calculateMinimumAdditionalPages(people, profileType)}
          maximum={200}
          onChange={setAdditionalPages}
        />
      </Section>

      <Section title="Cover Type">
        <Selector selected={coverType} onSelect={setCoverType}>
          <Selector.Option caption="Softback" value={COVERS.SOFT} />
          <Selector.Option caption="Hardback" value={COVERS.HARD} />
          <Selector.Option caption="Leatherette Hardback" value={COVERS.LEATHERETTE} />
        </Selector>
      </Section>

      <Section title="Optional Extras">
        <Selector isMultiSelect selected={extras} onSelect={setExtras}>
          <Selector.Option caption="Printed Endpapers (Front)" value={EXTRAS.FRONT_ENDPAPERS} />
          <Selector.Option caption="Printed Endpapers (Back)" value={EXTRAS.REAR_ENDPAPERS} />
          <Selector.Option caption="Ribbon Marker" value={EXTRAS.RIBBON_MARKER} />
          <Selector.Option caption="Rear Cover Foil Stamping" value={EXTRAS.FOIL_STAMPING} />
        </Selector>
      </Section>

      <Price
        copies={copies}
        people={people}
        additionalPages={additionalPages}
        profileType={profileType}
        coverType={coverType}
        extras={extras}
      />
    </>
  );
};

export default PricingCalculator;
