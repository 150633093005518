import React from 'react';
import PropTypes from 'prop-types';
import { calculatePrice } from './calculator';

const Price = ({
  copies, people, additionalPages, profileType, coverType, extras,
}) => {
  const {
    basePrice,
    additionalPagesPrice,
    coverPrice,
    extrasPrice,
    profilePages,
    standardPages,
  } = calculatePrice(copies, people, additionalPages, profileType, coverType, extras);

  const totalPrice = basePrice + additionalPagesPrice + coverPrice + extrasPrice;
  const totalPages = profilePages + standardPages + additionalPages;

  const renderRow = (title, value, unit = null, decimalPlaces = null) => {
    let valueProcessed = value;
    if (decimalPlaces >= 0) {
      valueProcessed = value.toFixed(decimalPlaces);
    }

    return (
      <div>
        <dt>{title}</dt>
        <dd>
          {unit}
          {valueProcessed}
        </dd>
      </div>
    );
  };

  return (
    <>
      <div>
        <dl>
          <title>Price</title>
          {renderRow('Base Price', basePrice, '£', 2)}
          {renderRow('Additional Pages', additionalPagesPrice, '£', 2)}
          {renderRow(`Cover (${coverType}) `, coverPrice, '£', 2)}
          {renderRow('Extras', extrasPrice, '£', 2)}
          {renderRow('Total price per book', totalPrice, '£', 2)}
        </dl>
      </div>
      <div>
        <dl>
          <title>Pages (B5)</title>
          {renderRow('Profile pages', profilePages)}
          {renderRow('Standard pages', standardPages)}
          {renderRow('Additional pages', additionalPages)}
          {renderRow('Total pages', totalPages)}
        </dl>
      </div>
    </>
  );
};

Price.propTypes = {
  copies: PropTypes.number.isRequired,
  people: PropTypes.number.isRequired,
  additionalPages: PropTypes.number.isRequired,
  profileType: PropTypes.number.isRequired,
  coverType: PropTypes.number.isRequired,
  extras: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default Price;
