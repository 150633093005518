import React from 'react';
import PropTypes from 'prop-types';

const Slider = ({
  title, subtitle, value, minimum, maximum, onChange,
}) => {
  const handleChange = (event) => {
    let suppliedValue = parseInt(event.target.value, 10);
    suppliedValue = Math.max(suppliedValue, minimum);
    if (maximum) {
      suppliedValue = Math.min(suppliedValue, maximum);
    }
    onChange(suppliedValue);
  };

  const percentValue = (value - parseInt(minimum, 10))
    / (parseInt(maximum, 10) - parseInt(minimum, 10));

  return (
    <div>
      <p>{title}</p>
      <p>{subtitle}</p>
      <div className="flex items-center gap-x-4">
        <div>
          <input type="number" value={value} onChange={handleChange} />
        </div>
        <div className="range-slider-wrapper flex flex-1">
          <input
            type="range"
            value={value}
            max={maximum}
            min={minimum}
            className="range-slider-input flex-grow"
            style={{ backgroundImage: `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${percentValue}, #e2e2e2), color-stop(${percentValue}, white))` }}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  maximum: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

Slider.defaultProps = {
  maximum: null,
};

export default Slider;
